.main-table
{
  padding: 4px;

  a {
    text-decoration: none;
    color: white;
  }
}

.table-actions
{
  margin-top: -4px;
}